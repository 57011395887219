import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `6b-sherwood-cres-paralowie-south-australia`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const SherwoodCresParalowieSouthAustralia = () => (
  <Layout>
    <Seo
      title="Improved Liveability SDA Property in Paralowie – Accessible Living"
      description="Discover an Improved Liveability SDA property in Paralowie, offering accessible living with features designed for enhanced comfort and independence."
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default SherwoodCresParalowieSouthAustralia
